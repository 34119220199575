<template>
    <div class="register">
        <div class="index_inner">
            <div class="index_banner">
                <img class="img" src="../assets/image/banner.jpg" alt="">
            </div>
            <router-view/>
        </div>
        <div class="register_body">
<!--            <span class="register_title">请选择礼品</span>-->
<!--            <div class="gift_list">-->
<!--                <div class="radio_box">-->
<!--                    <input type="radio" id="gift1" name="gift" value="洗牙卡" v-model="staff.recGift"/>-->
<!--                    <label for="gift1">纸品套装</label>-->
<!--                </div>-->
<!--            </div>-->
            <input type="hidden" v-model="staff.recGift" value="纸品套装">
            <span class="register_title">收件信息</span>
            <div class="register_body_input_box">
                <div>
                    <input type="text" v-model="staff.recName" placeholder="请输入收件人">
                    <span class="staff_input_warn"
                          :class="this.staff.recName.length!==0?'success':'error'">
            {{ this.staff.recName.length === 0 ? '*' : '√' }}</span>
                </div>
                <div>
                    <input type="tel" v-model="staff.recTel" placeholder="请输入收件联系电话">
                    <span class="staff_input_warn"
                          :class="valTelFormat(staff.recTel)?'success':'error'">
          {{ this.staff.recTel.length === 0 ? '*' : (valTelFormat(staff.recTel) ? '√' : '请检查格式') }}</span>
                </div>
            </div>
            <div class="register_addr_input_box">
                <div class="city_wrapper">
                    <span class="city_wrapper_title">请输入收件地址：</span>
                    <area-select type='text' :level='2' v-model="staff.recCity" :data="pcaa"></area-select>
                </div>
                <input type="text" v-model="staff.recAddr" placeholder="请输入详细地址">
            </div>

            <div class="register_body_button_box">
                <button class="register_button online"
                        @click="showconfirm()"
                        :class=" isActive() ? 'active' : ''"
                        :disabled="isDisabled()"
                >{{ buttonInner }}
                </button>
            </div>
        </div>
        <div class="copyright">活动最终解释权归思爱普（中国）有限公司北京分公司工会所有</div>
        <!--    <Error v-if="isError" @close="isError=false" :message="message"></Error>-->
        <Confirm v-if="isConfirm" @confirm="confirmFn" @cancel="cancelFn" :message="confirmMsg"></Confirm>
    </div>
</template>

<script>
import {valTel,valUserid,valEmail} from "./valid";
import {mapActions, mapGetters, mapState} from "vuex";
import axios from 'axios'
import Error from '../components/error'
import Confirm from "../components/confirm";
import pcaa from "area-data/pcaa";
export default {
  components:{
    Error,
    Confirm
  },
  data(){
    return{
      buttonInner:'提交信息',
      isClick : true,
      // isError:false,
      message:'',
      isConfirm:false,
      confirmMsg:'请确认您的收件信息，提交后将无法更改。',

      pcaa: pcaa,
    }
  },
  computed:{
    ...mapState({
      staff: state => state.staff
    })
  },
  mounted() {
  },
  methods:{
    ...mapGetters({
      getScore:'getScore',
      getResult:'getResult',
        getStaff:'passport/getStaff'
    }),
    showconfirm(){
      this.isConfirm= true
    },
    cancelFn(){
      console.log('qqq');
      this.isConfirm = false
    },

    confirmFn(){
      console.log(this.isClick);
      if (this.isClick){
        this.isClick = false
        axios.post('/submit',{score:this.getScore(),result:this.getResult(),staff:this.staff}).then(rsp=>{
          this.$router.replace({name:'score'})
        }).catch((e)=>{
          alert(e.response.data.message)
          // this.isError = true
          // this.message = e.response.data.message
          this.isClick = true
        })
      }
    },
    isActive(){
      return  this.staff.recName.length!==0 &&
          this.staff.recAddr.length!==0 &&
          this.staff.recCity.length !== 0 &&
          valTel(this.staff.recTel)
    },
    isDisabled(){
      return !(valTel(this.staff.recTel)) ||
          this.staff.recName.length ===0 ||
          this.staff.recCity.length == 0 ||
          this.staff.recAddr.length===0
    },
    valTelFormat(input){
      return valTel(input);
    },
    valUseridFormat(input){
      return valUserid(input);
    },
    valEmailFormat(input){
      return valEmail(input);
    },
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'
.error
  color #B91C1C
.success
  color green
.register
  width 100%
  height 100%
  //position relative
  display flex
  flex-direction column
  align-items center
  .register_body
    width 100%
    display flex
    flex-direction column
    align-items center
    justify-content flex-start
    padding 50px 75px 0
    box-sizing border-box
    .register_title
      titleSet()
      color $theme
      line-height 101px
      letter-spacing 0.2em
    .register_gift
      .register_gift_info
        display block
        font-size 24px
        //font-family "source han sans cn"
        fontFamily()
        color $deepGray
        line-height 24px
        font-weight 500
        padding-bottom 20px
      .gift_img
        width 100%
        display flex
        flex-direction column
        align-items  center
        img
          width 400px

    .gift_list
      width 100%
      .radio_box
        display flex
        align-items flex-start
        font-size 32px
        margin-bottom 10px
        input
          flex none
          width 36px
          height 36px
        &:first-child input
            margin-top 10px

    .register_body_input_box
      padding-top 43px
      width 100%
      height 200px
      //background-color gray
      display flex
      flex-direction column
      align-items center
      div
        width 600px
        height 68px
        //padding-bottom  43px
        border-bottom 2px solid $gray
        position relative
        margin-bottom 43px
        input
          width 100%
          height 68px
          background none
          outline none
          border 0
          font-size 24px
          //font-family "source han sans cn"
          fontFamily()
          line-height 68px
        input::placeholder
                color #999
        input::focus
                outline none
        .staff_input_warn
          position absolute
          top 0
          right 0
          line-height 68px

    .register_addr_input_box
      width 100%
      height 450px
      display flex
      flex-direction column
      align-items center
      justify-content space-between
      //div
      //  width 600px
      //  height 68px
      //  //padding-bottom  43px
      //  border-bottom 2px solid $gray
      //  position relative
      //  margin-bottom 43px
      input
        width 100%
        height 68px
        background none
        outline none
        border 0
        font-size 24px
        //font-family "source han sans cn"
        fontFamily()
        line-height 68px
        border-bottom 2px solid $gray
        margin-bottom 43px
      input::placeholder
              color #999
      input::focus
              outline none
      //  .staff_input_warn
      //    position absolute
      //    top 0
      //    right 0
      //    line-height 68px
      .city_wrapper
        width 100%
        //height 300px
        //border-bottom none
        //background green
        display flex
        flex-direction column
        .city_wrapper_title
          display block
          font-size 32px
          padding-bottom 20px
    //float right
    .register_body_img_box
      padding 78px 0 60px
      width 100%
      //height 236px
      display flex
      justify-content center
      align-items center
      img
        width 474px
        height 236px
    .register_body_button_box
      width 100%
      //padding-top 80px
      padding-bottom 10px
      display flex
      flex-direction column
      align-items center
      .register_button
        buttonSet()
        background-color gray
      .active
        background $theme
.index_inner{
    width: 750px;
    height: 100%;
  }
  .index_img_box{
    position: absolute;
    top: 0;
    width: 750px;
    padding: 40px 0 0 40px;
    box-sizing: border-box;
  }
  .index_logo{
    width: 88px;
    height: 122px;
  }
  .index_banner{
    width:100%
  }
  .index_banner>.img{
    width:100%;
    box-shadow: 10px 10px 15px rgba(0,0,0,0.2);
  }

.copyright
    width 100%
    color $gray
    margin-top 20px
    text-align center



::v-deep area-cascader-wrap{
  display inline-block
}
::v-deep .area-select {
  position: relative;
  display: inline-block;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 68px;
  width: 100%;
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e1e2e6;
  margin 0
}
::v-deep .area-select-wrap .area-select {
  margin 0 0 20px
}
::v-deep .area-select .area-selected-trigger {
  position: relative;
  display: block;
  font-size: 30px;
  cursor: pointer;
  margin: 0;
  line-height 68px;
  overflow: hidden;
  white-space: nowrap;
  //text-overflow: ellipsis;
  height: 100%;
  padding: 0 0 0 44px
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 10px;
}
::v-deep .area-selectable-list-wrap{
  max-height 500px
}
::v-deep .area-select .area-select-icon {
  border 10px solid transparent
  border-top-color: #a1a4ad;
  position: absolute;
  top: 50%;
  //margin-top: -0.026667rem;
  right: 40px;
}
::v-deep .area-selectable-list {
  font-size: 40px !important;
}
::v-deep .area-selectable-list .area-select-option{
  padding  0 0 0 44px
  font-size 30px
  height 68px
  line-height 68px
}
::v-deep .area-selectable-list .area-select-option.selected {
  background-color: #e4e8f1;
  color $theme
  font-weight: 700
}
::v-deep .cascader-menu-list .cascader-menu-option.selected {
  background-color: #e4e8f1;
  color $theme
  font-weight: 700
}
//::v-deep .cascader-menu-list .cascader-menu-option {
//  position: relative;
//  white-space: nowrap;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  cursor: pointer;
//  padding: 0 15px 0 20px;
//  height: 50px;
//  line-height: 50px
//}
::v-deep .cascader-menu-list {
  position: relative;
  margin: 0;
  font-size: 26px;
  color: #565656;
  padding: 6px 0;
  list-style: none;
  display: inline-block;
  height: 430px;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 150px;
  vertical-align: top;
  background-color: #fff;
  border-right: 1px solid #e4e7ed
}


</style>
